import { FormatPaintSharp, Margin } from "@mui/icons-material";
import { colors } from "@mui/material";
import React from "react";

const Home = () => {
  return (
    <div>
      <header style={headerStyle}>
        <h1>Welcome to ABJumbo Software Solutions</h1>
        <p>Your Partner in Innovation</p>
      </header>

      <nav style={navStyle}>
        <a  style={navAnchorStyle} href="#about">About Us</a>
        <a style={navAnchorStyle} href="#services">Services</a>
        <a style={navAnchorStyle} href="#contact">Contact Us</a>
        <a style={navAnchorStyle} href="#privacypolicy">Privacy Policy</a>
        <a style={navAnchorStyle} href="#terms">Terms</a>
        <a  style={navAnchorStyle} href="#refundpolicy">Refund Policy</a>
      </nav>

      <section id="about" style={sectionStyle}>
        <h2>About Us</h2>
        <p>
          ABJumbo BestGamez Pvt Ltd. is a leading software development company,
          providing innovative gaming solutions to help businesses transform.
        </p>
      </section>

      <section id="services" style={sectionStyle}>
        <h2>Our Services</h2>
        <p>
        We offer services that include Custom software development Training Courses &nbsp;&nbsp;&nbsp;
        <a href="https://kinggamez.com/client/MakePayment.html"><b>Pay Now</b></a>
        </p>
       
       
      </section>

      <section id="contact" style={sectionStyle}>
        <h2>Contact Us</h2>
        <p>Email: info@kinggamez.com | Phone: 9004450649</p>
      </section>

      <section id="privacypolicy" style={sectionStyle}>
        <h2>Privacy Policy</h2>
        <p>
          To know our Privacy Policy, click{" "}
          <a href="https://kinggamez.com/webadmin/privacy">here</a>.
        </p>
      </section>

      <section id="terms" style={sectionStyle}>
        <h2>Terms & Conditions</h2>
        <p>
          To know our Terms & Conditions, click{" "}
          <a href="https://kinggamez.com/webadmin/terms">here</a>.
        </p>
      </section>

      <section id="refundpolicy" style={sectionStyle}>
        <h2>Refund Policy</h2>
        <p>
          To know our refund policy, click{" "}
          <a href="https://kinggamez.com/client/refund_policy.html">here</a>.
        </p>
      </section>

      <footer style={footerStyle}>
        <p>&copy; 2024 ABJumbo BestGamez Pvt Ltd. All rights reserved.</p>
      </footer>
    </div>
  );
};

// Inline styles
const headerStyle = {
  backgroundColor: "#333",
  colors : "white",
  padding: "20px",
  textAlign: "center",
};

const navStyle = {
  textAlign: "center",
  padding: "10px",
  backgroundColor: "#444",
};

const sectionStyle = {
  padding: "20px",
  textAlign: "center",
  backgroundColor: "#f9f9f9",
  color:"black"
};

const navAnchorStyle= {
  color: "white",
  textDecoration: "none",
  margin: "0 15px",
  fontSize: "18px",
}

const footerStyle = {
 backgroundColor:"#333",
  color:"white",
  textAlign: "center",
  padding: "10px",
  position: "relative",
  width: "100%",
  bottom: "0",
};

export default Home;
