import React, { Fragment, useState } from "react";
import { Box,
    useTheme,
    MenuItem,
    Menu,
    Button,
    IconButton, } from "@mui/material";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useGetCenterCoinDataQuery } from "state/api"; // replace with the appropriate hook
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { Download } from "@mui/icons-material";
import * as XLSX from "xlsx";

const GameData = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetCenterCoinDataQuery(); // replace with the new query
  
  const result = data?.data || []

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  const filteredResults = result.filter((game) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;

    const createdAt = parseISO(game.createdAt);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1); // Include the full end date

    return createdAt >= dateRange[0].startDate && createdAt < endDate;
  });

  const columns = [
    {
      field: "gameId",
      headerName: "Game ID",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "roundCount",
      headerName: "Round Count",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "playerName",
      headerName: "Player Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "numbers",
      headerName: "Numbers",
      flex: 0.7,
      minWidth: 100,
      renderCell: ({ row }) => {
        return `[${row.number1}, ${row.number2}]`;
      },
    },

   
    {
      field: "resultCoin",
      headerName: "Result Coin",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "betCoins",
      headerName: "Bet Coins",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "coinsWonLost",
      headerName: "Won/Lost",
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ value }) => (value ? "Won" : "Lost"),
    },
    {
      field: "tableValue",
      headerName: "Table Value",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.8,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {new Date(params.value).toDateString()}{" "}
            {new Date(params.value).toLocaleTimeString()}
          </div>
        );
      },
    },
  ];

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredResults.map((game) => ({
        gameId: game.gameId,
        category: game.category,
        roundCount: game.roundCount,
        playerName: game.playerName,
        numbers: `[${game.number1}, ${game.number2}]`,
        resultCoin: game.resultCoin,
        betCoins: game.betCoins,
        wonLost: game.coinsWonLost ? "Won" : "Lost",
        tableValue: game.tableValue,
        createdAt: `${new Date(game.createdAt).toDateString()} - ${new Date(
          game.createdAt
        ).toLocaleTimeString()}`,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Game Data");
    XLSX.writeFile(workbook, `centerCoin_data.xlsx`);
  };

  return (
    <Fragment>
      <Box m="1.5rem 2.5rem">
      <Header title="Center Coin Data" subtitle="List of Center Coin Round Bets" />
      <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <Button
            type="button"
            variant="contained"
            onClick={downloadExcel}
            sx={{ mt: 2, alignSelf: "flex-end" }}
            startIcon={<Download />}
          >
            Download Excel
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
          >
            {isDateRangeOpen
              ? "Close Date Range Picker"
              : "Open Date Range Picker"}
          </Button>
          {isDateRangeOpen && (
            <Box
              position="absolute"
              top="100%"
              right="0"
              zIndex="1"
              boxShadow={3}
              bgcolor="background.paper"
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                maxDate={new Date()}
              />
            </Box>
          )}
        </Box>

        <Box
        
        mt="40px"
        height="75vh"
        width="100%"
        sx={{
          overflowX: "auto",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
        >
          <DataGrid
            isLoading= {isLoading}
            getRowId={(row) => row.id}
            rows={filteredResults}
            columns={columns}
            isRowSelectable={false}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default GameData;
