import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Collapse,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  AdminPanelSettingsOutlined,
  CasinoOutlined,
  ShieldOutlined,
  GavelOutlined,
  SupportAgent,
  AvTimer,
  RequestPageOutlined,
  CurrencyExchangeOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Client Facing",
    icon: null,
  },
  
  {
    text: "CenterCoin", // Parent item
    icon: <CasinoOutlined />,
    children: [          // Expandable children
      {
        text: "Data",
        icon: <ReceiptLongOutlined />,
      },
      {
        text: "History",
        icon: <ReceiptLongOutlined />,
      },
    ],
  },
  {
    text: "Housie",      // Parent item
    icon: <CasinoOutlined />,
    children: [          // Expandable children
      {
        text: "Data",
        icon: <ReceiptLongOutlined />,
      },
      {
        text: "History",
        icon: <ReceiptLongOutlined />,
      },
    ],
  },

  {
    text: "TitliSorat",      // Parent item
    icon: <CasinoOutlined />,
    children: [          // Expandable children
      {
        text: "Houses",
        icon: <CasinoOutlined />,
      },
      {
        text: "Sessions",
        icon: <AvTimer />,
      },
    ],
  },
  {
    text: "Support",
    icon: <SupportAgent />,
  },
  {
    text: "Users",
    icon: <Groups2Outlined />,
  },
  {
    name: "Purchase History",
    text: "coins-requests",
    icon: <RequestPageOutlined />,
  },
  {
    name: "Withdrawl History",
    text: "payout-requests",
    icon: <CurrencyExchangeOutlined />,
  },
  {
    text: "Transactions",
    icon: <ReceiptLongOutlined />,
  },

  {
    text: "Games",
    icon: null,
  },
  {
    text: "Timers",
    icon: <CasinoOutlined />,
  },
  {
    text: "Legal",
    icon: null,
  },
  {
    text: "Terms",
    url: "/webadmin/terms",
    icon: <GavelOutlined />,
  },
  {
    text: "Privacy Policy",
    url: "/webadmin/privacy",
    icon: <ShieldOutlined />,
  },
  {
    text: "Management",
    icon: null,
  },
  {
    text: "Admin",
    icon: <AdminPanelSettingsOutlined />,
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [expanded, setExpanded] = useState(null); // Track expanded items
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  // Toggle the expanded state
  const handleExpand = (itemText) => {
    setExpanded(expanded === itemText ? null : itemText);
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h6" fontWeight="bold">
                    King Sorat Portal
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, children, url, name }) => {
                const lcText = text.toLowerCase();

                // Render headings without icons
                if (!icon && !children) {
                  return (
                    <Typography
                      key={name || text}
                      sx={{ m: "2.25rem 0 1rem 3rem" }}
                    >
                      {name || text}
                    </Typography>
                  );
                }

                // Render expandable items
                if (children) {
                  return (
                    <Box key={text}>
                      <ListItem
                        disablePadding
                        onClick={() => handleExpand(text)}
                       
                       
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? "black"
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon
                            sx={{
                              ml: "2rem",
                              color:
                                active === lcText
                                  ? theme.palette.primary[600]
                                  : theme.palette.secondary[200],
                              
                            }}
                          >
                            {icon}
                          </ListItemIcon>
                          <ListItemText primary={text} />
                          {expanded === text ? <ChevronLeft /> : <ChevronRightOutlined />}
                        </ListItemButton>
                      </ListItem>
                
                      {/* Expand children items */}
                      {expanded === text &&
                        children.map(({ text: childText, icon: childIcon }) => (
                          <ListItem
                            key={childText}
                            disablePadding
                           
                            sx={{

                              backgroundColor:
                                active === `webadmin/${text.toLowerCase()}/${childText.toLowerCase()}`
                                  ? "#7a7f9d" // Active background color
                                  : "", // Hover background color
                              pl: 5,
                              
                            }}
                          >
                            <ListItemButton
                              onClick={() => {
                                const newActive = `/webadmin/${text.toLowerCase()}/${childText.toLowerCase()}`;
                                navigate(newActive); // Navigate to the URL
                                setActive(newActive); // Set active state
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  color:
                                    active === `webadmin/${text.toLowerCase()}/${childText.toLowerCase()}`
                                      ? ""
                                      : "",
                                }}
                              >
                                {childIcon}
                              </ListItemIcon>
                              <ListItemText primary={childText} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                    </Box>
                  );
                }
                
                
                

                // Render regular items
                return (
                  <Box key={name || text} sx={{ padding: "2px" }}>
                    <ListItem
                      disablePadding
                      sx={{
                        background:
                          `webadmin/${lcText}` === active ? "#7a7f9d" : "",
                      }}
                    >
                      <ListItemButton
                        onClick={() => {
                          navigate(`/webadmin/${lcText}`);
                          setActive(`/webadmin/${lcText}`);
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={name || text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Box>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;


//export default Sidebar;
