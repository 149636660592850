import React, { Fragment, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useGetCenterCoinHistoryQuery } from "state/api"; // replace with the appropriate hook
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { Download } from "@mui/icons-material";
import * as XLSX from "xlsx";

const GameHistoryData = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetCenterCoinHistoryQuery(); // replace with the new query
  const result = data?.data || [];
    console.log("data",result)
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  let filteredResults = result?.filter((game) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;

    const gameTime = parseISO(game.gameTime);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1); // Include the full end date

    return gameTime >= dateRange[0].startDate && gameTime < endDate;
  });

  const columns = [
    {
      field: "gameId",
      headerName: "Game ID",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "playersCount",
      headerName: "Players Count",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "totalBets",
      headerName: "Total Bets",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "totalRounds",
      headerName: "Total Rounds",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "entryCoinValue",
      headerName: "Entry Coin Value",
      flex: 0.7,
      minWidth: 100,
    },
    {
      field: "gameTime",
      headerName: "Game Time",
      flex: 0.7,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {new Date(params.value).toDateString()}{" "}
            {new Date(params.value).toLocaleTimeString()}
          </div>
        );
      },
    },
    {
      field: "gameDuration",
      headerName: "Game Duration",
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: "commissionReceived",
      headerName: "Commission Received",
      flex: 0.7,
      minWidth: 100,
    },
  ];

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredResults.map((game) => ({
        gameId: game.gameId,
        playersCount: game.playersCount,
        totalBets: game.totalBets,
        totalRounds: game.totalRounds,
        entryCoinValue: game.entryCoinValue,
        gameTime: `${new Date(game.gameTime).toDateString()} - ${new Date(
          game.gameTime
        ).toLocaleTimeString()}`,
        gameDuration: game.gameDuration,
        commissionReceived: game.commissionReceived,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Game History");
    XLSX.writeFile(workbook, `centerCoin_history.xlsx`);
  };

  return (
    <Fragment>
      <Box
       m="1.5rem 2.5rem"
       
       >
        <Header title="Game History" subtitle="List of Game Sessions" />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <Button
            type="button"
            variant="contained"
            onClick={downloadExcel}
            sx={{ mt: 2, alignSelf: "flex-end" }}
            startIcon={<Download />}
          >
            Download Excel
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
          >
            {isDateRangeOpen
              ? "Close Date Range Picker"
              : "Open Date Range Picker"}
          </Button>
          {isDateRangeOpen && (
            <Box
              position="absolute"
              top="100%"
              right="0"
              zIndex="1"
              boxShadow={3}
              bgcolor="background.paper"
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                maxDate={new Date()}
              />
            </Box>
          )}
        </Box>

        <Box
          mt="40px"
          height="75vh"
          width="100%"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
         
        >
          <DataGrid
            loading={isLoading}
            getRowId={(row) => row.id}
            rows={filteredResults}
            columns={columns}
            isRowSelectable={false}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default GameHistoryData;
